@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");

body{
  background-color: #fff;
  font-family: "Prata", serif !important;
  color: #333333;
  font-size: 15px;
  overflow-x: hidden;
  margin: 0 auto;
}
li{
  list-style: none;
}
ul{
  padding: 0 !important;
}
button, a{
  cursor: pointer !important;
}
p{
  line-height: 2rem;
  font-size: 18px;
}
sup {
  top: -0.5cm !important;
}
.spacer{
  margin-top: 15%;
}
.logo{
  width: 80px;
  border-radius: 50%;
}


.nav-link{

  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  
}
.nav-link:hover{
 color: #c1a35f!important;
}
.nav-links-container:active:first-child{
  color: #c1a35f!important;
}

.carousel-control-prev-icon, .carousel-control-next-icon{
  background-color: #c1a35f;
 
}
.nav-container{
  --bs-gutter-x: 0 !important;
}
.carousel-room a {
  background-color: transparent !important;
}
.close-button{
font-size: 40px;

color: rgba(255, 255, 255, 0.55);
cursor: pointer;

}
.when-collapses{
  padding-bottom: 100px;
  margin-top: 8.9px;
}
.carousel-item-custom::before{ 
    content:'';
    background-color: rgba(0, 0, 0, 0.7);
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
  
}
.carousel-indicators  button{
 width: 20px !important;
 height: 20px !important;
 border-radius: 50%;
 background-color: transparent!important;
 border:2px solid #c1a35f!important;
}


.image-hover:hover{
  min-height: 300px !important;
}


.form-control, .form-select{
  border-radius: 0% !important;
  padding: 2% !important;
}
.decoro{
  color: #c1a35f!important;
  font-weight: 800;
}
.titolo{
  text-transform: uppercase;
  font-weight: 800;
}
.about-text{
  line-height: 1.8rem;
  font-size: 17px;
}
.tangerine, .decoro{
  font-family: "Tangerine" !important;
  font-size: 2rem;
  
}
.counter{
font-size: 3rem;
font-weight: 800;
}

@media(min-width: 1200px){
  
  .carousel-control-prev-icon, .carousel-control-next-icon{
    width: 50px !important;
    height: 50px !important;
  }
  .carousel-caption{
    margin-bottom: 200px;
  }
  
  .decoro{
    font-size: 5.5rem !important;
  }
  .titolo{
    font-size: 3.5rem !important;
  }
  .decoro-small{
    font-size: 4rem !important;
  }
  .titolo-small{
    font-size: 2.5rem !important;
  }
  .testo{
    font-size: 2rem;
  }
  
}

.spoon-line{
 width: 80px;
 height: 2px;
 background-color: #c1a35f;
 margin: 20px 0;
}


@media(max-width: 1199px){
  .carousel-caption{
    margin-bottom: 100px;
  }
  .decoro{
    font-size: 3.5rem !important;
  }
  .titolo{
    font-size: 2.5rem !important;
  }
  .decoro-small{
    font-size: 3.1rem !important;
  }
  .titolo-small{
    font-size: 2rem !important;
  }
  .testo{
    font-size: 1.5rem;
   }
  
}

@media(max-width: 768px){
 
  .decoro{
    font-size: 2rem !important;
  }
  .titolo{
    font-size: 1.8rem !important;
  }
  .decoro-small{
    font-size: 2rem !important;
  }
  .titolo-small{
    font-size: 1.5rem !important;
  }
  .testo{
    font-size: 1rem;
   }
  
}

.testimone{
  font-style: italic;
}
.testimone-text{ 
  font-size: 1.5rem;
  line-height: 2.6rem;
  font-style: italic;
}
@media(max-width: 600px){
  .testimone-text{ 
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-style: italic;
  }
}

    

@media (max-width: 768px){ 
  .line{
    border-bottom: #e6e4e4 0.5px solid !important;
    width: 50% !important;
    margin: 0 auto;
  }
}


.contatto{
  color:#c1a35f;
  font-size: 1.2rem;
  text-transform: uppercase;

}

.logo-loader{
  width: 100px;
  height: 100px;
  background-color: black;
  border-radius: 50%;
}

.admin-link{
  color: #fff;
  margin-left: 10px;
  font-size: 12px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
}
.admin-container{
  background-color: #555;
  text-align: center;
}
.error{
  color: red;
  font-size: 12px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  line-height: 15px;
}
.alert-container{
  width: 200px;
  margin-top: 100px;
}